/* NOTE: Must come first, so classes below override as needed */
/* bootstrap-4.x .form-control {height} values used as `min-height` to mirror output sizes */
/* `xs` (bootstrap-3.3 - .btn - h:22px) */
.btn-group-xs {
	>.btn {
		padding: .35rem .4rem .25rem .4rem;
		font-size: .875rem;
		line-height: .5;
		border-radius: .2rem;
	}
}
.btn-xs {
	padding: 0.25rem 0.4rem;
	font-size: .675rem;
	line-height: 1.5;
	border-radius: .2rem;
	border: none;
}
.switch {
	position: relative;
	overflow: hidden;
	border-radius: 17px;
	input[type="checkbox"] {
		display: none;
	}
}
.switch.btn.btn-light {
	border-color: rgba(0, 0, 0, .15);
}
.switch.btn.btn-outline-light {
	border-color: rgba(0, 0, 0, .15);
}
.switch-group {
	position: absolute;
	width: 200%;
	top: 0;
	bottom: 0;
	left: 0;
	transition: left 0.35s;
	-webkit-transition: left 0.35s;
	-moz-user-select: none;
	-webkit-user-select: none;
}
.switch.off {
	.switch-group {
		left: -100%;
	}
}
.switch-on {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 50%;
	margin: 0;
	border: 0;
	border-radius: 0;
}
.switch-off {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	right: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
	box-shadow: none;
}
.switch-handle {
	position: relative;
	margin: 0 auto;
	padding-top: 0px;
	padding-bottom: 0px;
	height: 100%;
	width: 0px;
	border-width: 0 1px;
	background-color: #fff;
}
.switch.btn-outline-primary {
	.switch-handle {
		background-color: var(--primary);
		border-color: var(--primary);
	}
}
.switch.btn-outline-secondary {
	.switch-handle {
		background-color: var(--secondary);
		border-color: var(--secondary);
	}
}
.switch.btn-outline-success {
	.switch-handle {
		background-color: var(--success);
		border-color: var(--success);
	}
}
.switch.btn-outline-danger {
	.switch-handle {
		background-color: var(--danger);
		border-color: var(--danger);
	}
}
.switch.btn-outline-warning {
	.switch-handle {
		background-color: var(--warning);
		border-color: var(--warning);
	}
}
.switch.btn-outline-info {
	.switch-handle {
		background-color: var(--info);
		border-color: var(--info);
	}
}
.switch.btn-outline-light {
	.switch-handle {
		background-color: var(--light);
		border-color: var(--light);
	}
}
.switch.btn-outline-dark {
	.switch-handle {
		background-color: var(--dark);
		border-color: var(--dark);
	}
}
.switch[class*="btn-outline"] {
	&:hover {
		.switch-handle {
			background-color: var(--light);
			opacity: 0.5;
		}
	}
}
.switch.btn {
	min-width: 7rem !important;
	min-height: calc(1.5em + .75rem + 2px);
}

.switch-on.btn {
	padding-right: 1.5rem;
}
.switch-off.btn {
	padding-left: 1.5rem;
}
.switch.btn-lg {
	min-width: 5rem;
	line-height: 1.5;
	min-height: calc(1.5em + 1rem + 2px);
}
.switch-on.btn-lg {
	padding-right: 2rem;
}
.switch-off.btn-lg {
	padding-left: 2rem;
}
.switch-handle.btn-lg {
	width: 2.5rem;
}
.switch.btn-sm {
	min-width: 3.25rem;
	min-height: calc(1.5em + .5rem + 2px);
}
.switch-on.btn-sm {
	padding-right: 1rem;
}
.switch-off.btn-sm {
	padding-left: 1rem;
}
.switch.btn-xs {
	min-width: 3.125rem;
	min-height: 1.375rem;
}
.switch-on.btn-xs {
	padding-right: .8rem;
}
.switch-off.btn-xs {
	padding-left: .8rem;
}
