@import './variable.scss';

.horizontal-field {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: $horizontal-field-border-bottom;
    height: $horizontal-field-height;
    &:focus-within {
        &:before {
            position: absolute;
            background: #258fbd;
            height: 0.1em;
            content: '';
            width: 66%;
            bottom: 0.35rem;
            right: 15px;
            border-collapse: collapse;
            z-index: 9999;
          }
    }
    .label {
        width: 30%;
        word-wrap: break-word;
        padding-right: 1em;
    }
    .form-control,.custom-radio-input, .rbt {
        width: 70%;
    }
    .rbt {
        border-bottom: none;
    }
    .rbt-input-main {
        width: 100%;
        // font-size: 0.875rem;
    }
    .custom-radio-input {
        height: $custom-radio-input-height;
        display: flex;
        align-items: center;
        justify-content: stretch;
        // border-bottom: 1px solid #ced4da
    }
    .form-control, .form-control-sm, .rw-widget-picker {
        border: none;
    }
    .font-active-color, .rbt-input-main, .rw-input {
        color: $font-active-color;
        font-weight: 400;
        // font-size: 13px;
    }
    .rw-state-focus {
        box-shadow: none;
    }
    .rw-widget-container{
        background: transparent;
        box-shadow: none !important;
        border: none !important;
        // border-bottom: #ccc 1px solid !important;
    }
    .card-header, .card-title {
        margin-bottom: 0.35rem;
        height: $horizontal-field-height;
        display: flex;
        align-items: center;
    }

    .form-control:disabled, .form-control[readonly] {
        border-radius: 4px;
        padding-left: 2px;
    }

}

.vertical-divider {
    border-right: $vertical-divider-border;
    // border-image: linear-gradient(to bottom, #fff, #d5d5d5) 1% 99%
  }
.vertical-divider-left {
    border-left: $vertical-divider-border;
    // border-image: linear-gradient(to bottom, #fff, #d5d5d5) 1% 99%
  }

.receipt-table {
    .rw-widget-picker, .form-control-sm{
      border: none;
    }
    thead th, tbody td{
        border-bottom: $tabel-border;
        border-top: $tabel-border;
        vertical-align: middle;
        height: $horizontal-field-height;
        border-right: $tabel-border;
        padding: 0px 4px !important;
        // text-indent: 6px;
    }
    thead th:last-of-type {
        border-right: 0;
    }
    .action-btns {
        border: none;
    }

    .form-control.is-invalid:focus {
            border-color: transparent;
            box-shadow: none;
    }
   
} 

@media screen and (max-width: 450px) {
    .rbt {
        border-bottom: $horizontal-field-border-bottom;
    }
}