// Sidebar Animation

.SidebarAnimation-appear {
  transform: translateX(-30px);
  opacity: 0;

  &.SidebarAnimation-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all .4s linear;
  }
}

// Tabs Animation

// .TabsAnimation-appear {
//   @extend .animated;
//   @extend .fadeInUp;
// }

.TabsAnimation-appear {
  opacity: 0.01;
  transform: translateY(5%);
}

.TabsAnimation-appear.TabsAnimation-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
  animation: slide-in-from-bottom 0.5s forwards;
}

.TabsAnimation-leave {
  opacity: 1;
  transform: translateY(-10%);
  .TabsAnimation-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
    animation: slide-out-left 0.5s forwards;
  }
}

.TabsAnimation-appear {
  opacity: 0.01;
  transform: translateY(-5%);
  .TabsAnimation-appear-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
    animation: slide-in-from-bottom 0.5s forwards;
  }
}

@keyframes slide-in-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translate(0%);
  }
}


// Header Animation

.HeaderAnimation-appear {
  transform: translateY(-30px);
  opacity: 0;

  &.HeaderAnimation-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all .4s linear;
  }
}

// Main Animation

.MainAnimation-appear {
  transform: translateY(-30px);
  opacity: 0;

  &.MainAnimation-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all .4s linear;
  }
}