.bg-light-gray {
	background-color: #f7f7f7 !important;
}
.border-lighter {
	border: 1px solid #f5f5f5;
}

.p-fixed {
	position: fixed;
}
.collapsible {
	display: none;
}
.collapsible.open {
	display: block;
}
.button-new {
	.btn-primary {
		color: #000;
		background-color: transparent !important;
		border-color: #2bace2;
		&:hover {
			color: #000;
			background-color: transparent !important;
			border-color: #2bace2;
		}
		.badge-light {
			background-color: #2bace2;
			color: #fff;
		}
	}
	button {
		padding-bottom: 0.25rem !important;
		padding-top: 0.25rem !important;
	}
	.btn-success {
		color: #000;
		background-color: transparent;
		border-color: #2fa84f;
		&:hover {
			color: #000;
			background-color: transparent;
			border-color: #2fa84f;
		}
		.badge-light {
			background-color: #2fa84f;
			color: #fff;
		}
	}
	.btn-danger {
		text-decoration: line-through;
		color: #000;
		background-color: transparent;
		border-color: #ea3d2f;
		&:hover {
			text-decoration: line-through;
			color: #000;
			background-color: transparent;
			border-color: #ea3d2f;
		}
		.badge-light {
			background-color: #ea3d2f;
			color: #fff;
		}
	}
	.btn-warning {
		color: #000;
		background-color: transparent;
		border-color: #f3aa18;
		&:hover {
			color: #000;
			background-color: transparent;
			border-color: #f3aa18;
		}
		.badge-light {
			background-color: #f3aa18;
			color: #fff;
		}
	}
	.btn-secondary {
		color: #000;
		background-color: transparent;
		border-color: #6c757d;
		&:hover {
			color: #000;
			background-color: transparent;
			border-color: #6c757d;
		}
		.badge-light {
			background-color: #6c757d;
			color: #fff;
		}
	}
	.btn-focus {
		color: #444054;
		background-color: transparent;
		border-color: #444054;
		&:hover {
			color: #444054;
			background-color: transparent;
			border-color: #444054;
		}
		.badge-light {
			background-color: #444054;
			color: #fff;
		}
	}
	.btn-info {
		color: #367bf5;
		background-color: #e5efff;
		border-color: #e5efff;
		box-shadow: none !important;
		&:hover {
			color: #367bf5;
			background-color: #e5efff;
			border-color: #e5efff;
			box-shadow: none !important;
		}
	}
}

.pagination_btn {
	color: #2f2dc9;
	background-color: #73c6eb;
	border-color: #e0f3ff;
}

.pagination_btn:disabled {
	color: #2f2dc9;
	background-color: #73c6eb;
	border-color: #e0f3ff;
    opacity: 0.6;
}

.pagination_btn:hover {
	color: #2f2dc9;
	background-color: #73c6eb;
	border-color: #e0f3ff;
}

.react-confirm-alert-body {
	border-radius: 2px;
	color: #adb5bd !important;
	text-align: center !important;
	width: 100% !important;
	>h1 {
		color: #495057;
		text-align: center;
		text-transform: none;
		position: relative;
		margin: 0.75rem 0;
		padding: 0;
		display: block;
		text-transform: capitalize;
		font-size: 1.5rem;
	}
}
.react-confirm-alert-button-group {
	justify-content: center !important;
	>button {
		background: #2e38cb !important;
	}
}
.react-confirm-alert-overlay {
	background: rgba(0, 0, 0, 0.5) !important;
}
.w-5 {
	width: 5%;
	input {
		// height: calc(1.5em + 0.5rem + 2px);
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.5;
		border-radius: 0.2rem;
	}
}

.w-10 {
	width: 10%;
	input {
		// height: calc(1.5em + 0.5rem + 2px);
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.5;
		border-radius: 0.2rem;
	}
}

.w-15 {
	width: 15%;
	input {
		// height: calc(1.5em + 0.5rem + 2px);
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.5;
		border-radius: 0.2rem;
	}
}
.w-20 {
	width: 20%;
}
.fs-25 {
	font-size: 21px;
	font-weight: 600 !important;
	margin-right: 4px;
	&::before {
		color: #fdb913 !important;
	}
}
.valight {
	vertical-align: text-top;
	padding-right: 0.25rem;
}
.valigntop {
	vertical-align: top;
}
select {
	option {
		&:hover {
			background-color: #2fa84f !important;
		}
	}
}
.rt-column {
	.col-sm-2 {
		border: 1px solid #e9ecef;
		padding: 6px 10px;
	}
	.col-sm-1 {
		border: 1px solid #e9ecef;
		padding: 6px 10px;
	}
	margin-bottom: 10px;
	font-size: 13px;
}
.fs-12 {
	font-size: 12px;
	font-weight: 600 !important;
	margin-right: 3px;
	&::before {
		color: #000 !important;
	}
}
.btn-primary1 {
	color: #fff;
	background-color: #2BACE2 !important;
	border-color: #2BACE2 !important;
	font-weight: 400;
}
.btn-focus1 {
	color: #28ace2 !important;
	background-color: #ffffff !important;
	border-color: #28ace2 !important;
}
.btn-success1 {
	color: #fff !important;
	background-color: #73B443 !important;
	border-color: #73B443 !important;
}
.ball-grid-pulse {
	background: url('/preloader57.gif');
	background-repeat: no-repeat;
	>div {
		visibility: hidden;
	}
}
.ball-grid-beat {
	>div {
		visibility: hidden;
	}
}
.pmd-textfield-filled-wrapper {
	.form-control {
		&:focus {
			border-color: transparent;
			box-shadow: none;
		}
	}
}
.pmd-textfield.pmd-textfield-filled {
	.pmd-textfield-filled-wrapper {
		background-color: transparent !important;
	}
}
.btn.btn-white {
	color: #3F4254;
	background-color: #e0f3ff;
	border-color: #28ace2;
	font-weight: 500;
}
.btn.btn-hover-primary {
	&:hover {
		&:not(.btn-text) {
			&:not(:disabled) {
				&:not(.disabled) {
					color: #FFFFFF !important;
					background-color: #28ace2 !important;
					border-color: #28ace2 !important;
					.svg-icon {
						svg {
							g {
								[fill] {
									-webkit-transition: fill 0.3s ease;
									transition: fill 0.3s ease;
									fill: #FFFFFF !important;
								}
							}
						}
					}
				}
			}
		}
	}
	&:focus {
		&:not(.btn-text) {
			color: #FFFFFF !important;
			background-color: #28ace2 !important;
			border-color: #28ace2 !important;
			.svg-icon {
				svg {
					g {
						[fill] {
							-webkit-transition: fill 0.3s ease;
							transition: fill 0.3s ease;
							fill: #FFFFFF !important;
						}
					}
				}
			}
		}
	}
}
.btn.btn-hover-primary.focus {
	&:not(.btn-text) {
		color: #FFFFFF !important;
		background-color: #28ace2 !important;
		border-color: #28ace2 !important;
		.svg-icon {
			svg {
				g {
					[fill] {
						-webkit-transition: fill 0.3s ease;
						transition: fill 0.3s ease;
						fill: #FFFFFF !important;
					}
				}
			}
		}
	}
}
.svg-icon.svg-icon-primary {
	svg {
		g {
			[fill] {
				-webkit-transition: fill 0.3s ease;
				transition: fill 0.3s ease;
				fill: #3699FF !important;
			}
		}
	}
}
.input-icon {
	span {
		right: 0;
		top: 0;
		bottom: 0;
		position: absolute;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		width: calc(1.5em + 1.3rem + 2px);
		cursor: pointer;
	}
	position: relative;
	.form-control {
		padding-left: 1rem;
		font-size: 14px;
	}
	i {
		font-size: 1.25rem;
	}
}
.form-control-solid {
	color: #3F4254;
	-webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
	transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
	transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
	transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
	&:active, .active, &:focus { background-color: #EBEDF3;
	border-color: #EBEDF3;
	box-shadow: none;
	color: #3F4254;
	-webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
	transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
	transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
	transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}
} 
.svg-icon.svg-icon-success {
		svg {
			g {
				[fill] {
					-webkit-transition: fill 0.3s ease;
					transition: fill 0.3s ease;
					fill: #28ace2 !important;
				}
			}
		}
}
.font-14 {
	font-size: 14px;
}
.sticky-toolbar {
	width: 60px;
	position: fixed;
	top: 30%;
	right: 0;
	list-style: none;
	margin: 0;
	z-index: 50;
	background: #ffffff;
	-webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
	box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	border-top-left-radius: 0.42rem;
	border-bottom-left-radius: 0.42rem;
	padding-right: 3.5rem !important;
}
.btn.btn-bg-light {
	background-color: #f2f5f8;
	border-color: #f2f5f8;
	i.f1 {
		&::before {
			color: #2fa84f;
			font-weight: 600;
			font-size: 16px;
		}
	}
	i.f2 {
		&::before {
			color: #2bace2;
			font-weight: 600;
			font-size: 16px;
		}
	}
	i.f3 {
		&::before {
			color: #ea3d2f;
			font-weight: 600;
			font-size: 16px;
		}
	}
}
